html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  opacity: 1;
  transition: opacity .25s ease-in-out;
}

a:hover {
  opacity: 0.7;
}

* {
  box-sizing: border-box;
}

button {
  background-color: #808080;
  border-radius: 50px;
  width: 100%;
  max-width: 240px;
  text-transform: none !important;
}

root {
  min-height: 100%
}
