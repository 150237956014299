@import url('https://fonts.googleapis.com/css?family=Poppins:400,500&amp;subset=latin-ext');
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

table {
  font-family: "Poppins" !important;
  th,
  td {
    font-weight: 500 !important;
    text-align: left !important;
  }
  th {
    &:first-child {
      padding-left: 20px;
    }
    &:nth-child(7) {
      //padding-right: 0 !important;
      text-align: right !important;
    }
    span {
      flex-direction: initial !important;
    }
  }
}

[class*="MuiTablePagination"],
[class*="MuiTypography"] {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
}

[class*="MuiIconButton"] {
  padding: 5px !important;
  &:hover {
    background-color: transparent !important;
  }
}

[class*="MuiTablePagination"] {
  [class*="MuiIconButton-label"] {
    background-color: #00326b;
    border-radius: 50%;
    transition: all ease .3s;
    &:hover {
      background-color: lighten(#00326b, 5%);
    }
    svg {
      fill: #fff;
    }
  }
}

[class*="MuiNativeSelect-select"] {
  &:focus {
    background-color: transparent !important;
  }
}

h1 {
  font-weight: 600;
  font-size: 30px;
  margin: 0;
}

h2,
h3 {
  margin: 0 0 32px;
}

a {
  color: #093769;
  &:hover {
    text-decoration: none;
  }
}

p {
  margin-top: 0;
}

form {
  margin-bottom: 30px;
}

button {
  width: 100%;
  max-width: 240px;
  height: 45px;
  border-radius: 50px !important;
  background-color: #00326b;
  transition: opacity .25s ease-in-out;
  &:hover {
    opacity: .9;
  }
}

.Link--noUnderline {
  text-decoration: none;
}

.Detail-card {
  .Detail-arrow {
    right: 10px;
    transition: right .25s ease-in-out;
  }

  &:hover {
    .Detail-arrow {
      right: 7px!important;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Poppins';

  &.menuOpen {
    height: 100vh;
    position: fixed;
    width: 100%;
  }
}

* {
  box-sizing: border-box;
}
