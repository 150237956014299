@import url(https://fonts.googleapis.com/css?family=Poppins:400,500&amp;subset=latin-ext);
html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  opacity: 1;
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
}

a:hover {
  opacity: 0.7;
}

* {
  box-sizing: border-box;
}

button {
  background-color: #808080;
  border-radius: 50px;
  width: 100%;
  max-width: 240px;
  text-transform: none !important;
}

root {
  min-height: 100%
}

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

table {
  font-family: "Poppins" !important; }
  table th,
  table td {
    font-weight: 500 !important;
    text-align: left !important; }
  table th:first-child {
    padding-left: 20px; }
  table th:nth-child(7) {
    text-align: right !important; }
  table th span {
    flex-direction: initial !important; }

[class*="MuiTablePagination"],
[class*="MuiTypography"] {
  font-family: "Poppins" !important;
  font-weight: 500 !important; }

[class*="MuiIconButton"] {
  padding: 5px !important; }
  [class*="MuiIconButton"]:hover {
    background-color: transparent !important; }

[class*="MuiTablePagination"] [class*="MuiIconButton-label"] {
  background-color: #00326b;
  border-radius: 50%;
  -webkit-transition: all ease .3s;
  transition: all ease .3s; }
  [class*="MuiTablePagination"] [class*="MuiIconButton-label"]:hover {
    background-color: #003e85; }
  [class*="MuiTablePagination"] [class*="MuiIconButton-label"] svg {
    fill: #fff; }

[class*="MuiNativeSelect-select"]:focus {
  background-color: transparent !important; }

h1 {
  font-weight: 600;
  font-size: 30px;
  margin: 0; }

h2,
h3 {
  margin: 0 0 32px; }

a {
  color: #093769; }
  a:hover {
    text-decoration: none; }

p {
  margin-top: 0; }

form {
  margin-bottom: 30px; }

button {
  width: 100%;
  max-width: 240px;
  height: 45px;
  border-radius: 50px !important;
  background-color: #00326b;
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out; }
  button:hover {
    opacity: .9; }

.Link--noUnderline {
  text-decoration: none; }

.Detail-card .Detail-arrow {
  right: 10px;
  -webkit-transition: right .25s ease-in-out;
  transition: right .25s ease-in-out; }

.Detail-card:hover .Detail-arrow {
  right: 7px !important; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

body {
  font-family: 'Poppins'; }
  body.menuOpen {
    height: 100vh;
    position: fixed;
    width: 100%; }

* {
  box-sizing: border-box; }

